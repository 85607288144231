import React from 'react';

const WithWhatsapp = ({ number }) => {
	return (
		<div style={{ padding: "10px", backgroundColor: "#fff" }}>
			<span style={{ spanAlign: 'center', fontSize: 18, fontWeight: 'bold' }}>{number}</span>
			<div style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 10 }}>
				<a  style={{ padding: "10px", borderRadius: "10px" }}
					href={`https://wa.me/${number}?span=
  नमस्ते Aims 🙏
  म Aims Integration Education Websiteको प्रयोगकर्ता हुँ। यहाँ APPमा मेरो डाटा छ।
  `
					}
				>
					<span>
						<i className="fa fa-whatsapp" style={{fontSize:30}} />
					</span>
				</a>
				<a style={{ padding: 10, borderWidth: 0.5, borderColor: "#ddd", borderRadius: 10 }}
					href={`tel:${number}`}
				>
					<span>
						<i className="fa fa-phone"  style={{fontSize:30}} />
					</span>
				</a>
			</div>
		</div>
	)
}
const WithoutWhatsapp = ({ number }) => {
	return (
		<div>
			<span style={{ spanAlign: 'center', fontSize: 18, fontWeight: 'bold' }}>{number}</span>
			<div style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 10 }}>
				<a style={{ padding: 10, borderWidth: 0.5, borderColor: "#ddd", borderRadius: 10 }}
					href={`tel:${number}`}
				>
					<span>
						<i className="fa fa-phone" style={{fontSize:30}} />
					</span>
				</a>
			</div>
		</div>
	)
}


export default function ContactUS() {
	return (
		<div className='row'>
			<div className="row">
				{/* {loginSuccess &&
			<Navigate to="/" />
		} */}
				<div className="col-lg-2 col-1"></div>
				<div className="col-lg-8 col-10 shadow signin-container" style={{ backgroundColor: "#fff" }}>

					<div>
						<div style={{ backgroundColor: "#fff", borderColor: "#bbb", borderWidth: 1, margin: 10, padding: 10 }}>
							<span style={{ spanAlign: "center" }}>
								तपाई पहिलो पटक EXAM दिदैं हुनुहुन्छ वा कुनै समस्या परेमा  तल दिईएको सम्पर्क नम्बरमा सम्पर्क गर्नु  होला |{"\n"}
							</span>
						</div>
							<div className='row'>
						<div className='col-sm-8' style={{ borderColor: "#bbb", borderWidth: 1, padding: 10, margin: 10, marginTop: 0, backgroundColor: "#fff" }}>
							<span style={{ spanAlign: "left" }}>
								TECHNICAL SUPPORT :
							</span>
							<div className='row'>
								<div className='col-lg-6'>
									<WithWhatsapp number={"+977-9851234991"} />
								</div>
								<div className='col-lg-6'>
									<WithWhatsapp number={"+977-9800848483"} />
								</div>
							</div>
						</div>
						<div className='col-sm-4' style={{ borderColor: "#bbb", borderWidth: 1, padding: 10, margin: 10, marginTop: 0, backgroundColor: "#fff" }}>
							<span style={{ spanAlign: "left" }}>
								QUESTION/ANSWER SUPPORT :
							</span>
							<div style={{ flexDirection: "row" }}>
								<div style={{ width: "100%" }}>
									<WithWhatsapp number={"+977-9864735696"} />
								</div>
							</div>
						</div>
						</div>
						{/* <div style={{ marginVertical: 10, margin: 5, padding: 10, borderWidth: 1, backgroundColor: `#fff`, borderRadius: 10 }}>
							<div style={{ flexDirection: "row" }}>
								<a style={{ width: "70%" }}
									onPress={() => {

									}}
								>
									<div style={{ flexDirection: "row" }}>
										<div style={{ width: "70%", justifyContent: "center" }}>
											<span>
												Name: Uttam Thapa{'\n'}
												Esewa ID: 9851217472
											</span>
										</div>
									</div>
								</a>
								<div style={{ width: "25%", justifyContent: "center" }}>

									<img src={require("./esewa.png")} style={{ width: "100%", }} resizeMode="contain" />
									<img src={require("./esewa_logo.png")} style={{ width: "100%", }} resizeMode="contain" />
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</div>
		</div>
	)
}